body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
}

.header {
  display: flex;
  align-items: center;
  padding: 16px;
  background: #419ff4;
}

.logo {
  width: 50px;
}

.logo-title {
  color: #FFF;
  font-weight: bold;
  margin-left: 8px;
  font-size: 18px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.form-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 512px;
}

.form-title {
  margin-top: 48px;
  color: #419ff4;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.input-group {
  display: flex;
  align-items: center;
}

input[type="tel"],
input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

.next-button,
.verify-button,
.resend-button,
.success-button,
.delete-account-button {
  background-color: #419ff4;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
}

.next-button:hover,
.verify-button:hover,
.resend-button:hover {
  background-color: #0056b3;
}

.verify-button:disabled,
.next-button:disabled,
.resend-button:disabled {
  background-color: #aac7e0;
  cursor: not-allowed;
}

.delete-account-button {
  background-color: #c91d12;
}

.delete-account-button:hover,
.delete-account-button:disabled {
  opacity: 0.5;
}

.success-button {
  background-color: #01A601;
}

.success-button:hover {
  background-color: #016801;
}

.valid-field {
  width: 25px;
}

.phone-number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
  color: grey;
  background-color: rgba(170, 170, 170, 0.25);
}

.change-number-link {
  color: #419ff4;
  cursor: pointer;
  font-size: 14px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 475px;
}

.single-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 475px;
}

.otp-verified {
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.input-container{
    display: flex;
    align-items: center;
    gap: 12px;
    
    input {
      max-width: 475px;
    }
}


.success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    text-align: center;
    color: #01A601;
    line-height: 24px;
  }
}

.success-icon {
  width: 120px;
}

.errorMessage {
  color: #e81d1d;
  width: 100%;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 13px;
}